// React imports

// Remix imports
import { useFetcher } from '@remix-run/react'
import type { HeadersFunction, LoaderFunction } from '@remix-run/server-runtime'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'

// Third party imports

// Generated imports

// App imports
import Modal from '~/components/modals/Modal'
import H1 from '~/components/typography/H1'
import P2 from '~/components/typography/P2'
import { fetchGlobalGQL } from '~/fetch.server'
import useLang from '~/hooks/useLang'
import type { PickTranslations } from '~/i18n.server'
import { getTranslations } from '~/i18n.server'
import type { Language } from '~/i18n.universal'
import { getLang } from '~/i18n.universal'
import { CACHE_CONTROL } from '~/utils/cacheControl'
import type { GlobalDataAndTrans } from '~/utils/getGlobalDataAndTrans.server'
import { loaderJson } from '~/utils/jsonResponse.server'

const VIEW_CACHE = CACHE_CONTROL.ONE_HOUR

// ============================================================================
// HTML =======================================================================
// ============================================================================
interface Props {}

export const CreateAccountSuccessModal: React.FC<Props> = () => {
  const lang = useLang()
  const fetcherLoader = useFetcher<CreateAccountSuccessModalLoaderData>()

  useEffect(() => {
    if (fetcherLoader.state === 'idle' && fetcherLoader.data == null) {
      fetcherLoader.load(`/${lang}/modals/create-account-success/`)
    }
  }, [fetcherLoader, lang])

  if (isEmpty(fetcherLoader.data)) {
    return null
  }

  const trans = fetcherLoader.data.trans

  return (
    <Modal
      size="medium"
      contentClassName="max-w-[500px] mx-auto"
    >
      <H1>{trans.SubmissionReceived}</H1>
      <P2 className="mt-next-group-element">{trans.SubmissionReceivedText}</P2>
    </Modal>
  )
}

export default CreateAccountSuccessModal

// ============================================================================
// TRANS ======================================================================
// ============================================================================
export type CreateAccountSuccessModalTrans = PickTranslations<
  'SubmissionReceived' | 'SubmissionReceivedText'
>

export const getCreateAccountSuccessModalTrans = (lang: Language) => {
  return getTranslations(lang, ['SubmissionReceived', 'SubmissionReceivedText'])
}

// ============================================================================
// LOADERS ====================================================================
// ============================================================================
interface CreateAccountSuccessModalLoaderData {
  trans: CreateAccountSuccessModalTrans
  global: GlobalDataAndTrans
}

export let loader: LoaderFunction = async ({ request, params }) => {
  const lang = getLang(params)
  const trans = getCreateAccountSuccessModalTrans(lang)
  const { global, headers } = await fetchGlobalGQL(request, params)

  return loaderJson({ trans, global }, VIEW_CACHE, { headers })
}

// ============================================================================
// HEADERS ====================================================================
// ============================================================================
export const headers: HeadersFunction = () => {
  return {
    'Cache-Control': VIEW_CACHE,
  }
}
